import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Container from "../components/comp-layouts/Container";
import Owl from "../components/comp-layouts/Owl";
import Button from "../components/Button";
import theme from "../theme/theme.json";
import * as Icon from "react-feather";

const PostTemplate = ({ data }) => {
  const image = getImage(data.markdownRemark.frontmatter.image);
  const profile = data.markdownRemark.frontmatter;
  const url = data.site.siteMetadata.siteUrl;
  return (
    <>
      <SEO
        title={profile.title}
        description={profile.description}
        image={profile.image.publicURL}
        slug={data.markdownRemark.fields.slug}
      />
      <Layout>
        <Container mt="60px" mb="60px">
          <ProfileHeadGrid>
            <ProfileHeadText>
              <Name>{profile.title}</Name>
              <Position>{profile.coredetails.position}</Position>
            </ProfileHeadText>
            <ProfileImg image={image} alt={profile.alt} />
          </ProfileHeadGrid>
          <FeaturedGrid>
            {profile.coredetails.researchOneLink && (
              <FeaturedCard to={profile.coredetails.researchOneLink}>
                <div>
                  <FeaturedTitle>
                    {profile.coredetails.researchOneLabel}
                  </FeaturedTitle>
                  <FeaturedRM>View Publication »</FeaturedRM>
                </div>
                <Aligner>
                  <Icon.BookOpen />
                </Aligner>
              </FeaturedCard>
            )}
            {profile.coredetails.researchTwoLink && (
              <FeaturedCard to={profile.coredetails.researchTwoLink}>
                <div>
                  <FeaturedTitle>
                    {profile.coredetails.researchTwoLabel}
                  </FeaturedTitle>
                  <FeaturedRM>View Publication »</FeaturedRM>
                </div>
                <Aligner>
                  <Icon.BookOpen />
                </Aligner>
              </FeaturedCard>
            )}
            {profile.coredetails.newsOneLink && (
              <FeaturedCard to={profile.coredetails.newsOneLink}>
                <div>
                  <FeaturedTitle>
                    {profile.coredetails.newsOneLabel}
                  </FeaturedTitle>
                  <FeaturedRM>View News Article »</FeaturedRM>
                </div>
                <Aligner>
                  <Icon.Bookmark />
                </Aligner>
              </FeaturedCard>
            )}
            {profile.coredetails.newsTwoLink && (
              <FeaturedCard to={profile.coredetails.newsTwoLink}>
                <div>
                  <FeaturedTitle>
                    {profile.coredetails.newsTwoLabel}
                  </FeaturedTitle>
                  <FeaturedRM>View News Article »</FeaturedRM>
                </div>
                <Aligner>
                  <Icon.Bookmark />
                </Aligner>
              </FeaturedCard>
            )}
          </FeaturedGrid>
          <Owl>
            <div
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
          </Owl>
        </Container>
      </Layout>
    </>
  );
};

export default PostTemplate;

export const teamProfileQuery = graphql`
  query TeamProfileBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      fields {
        collection
        slug
      }
      html
      frontmatter {
        title
        description
        alt
        coredetails {
          newsOneLabel
          newsOneLink
          newsTwoLabel
          newsTwoLink
          order
          researchOneLabel
          researchTwoLabel
          researchTwoLink
          researchOneLink
          position
        }
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const ProfileImg = styled((props) => <GatsbyImage {...props} />)`
  border-radius: 9999px;
  @media (max-width: 640px) {
    width: 75%;
    margin: auto;
  }
`;

const ProfileHeadGrid = styled.div`
  display: grid;
  grid-template-columns: 3.7fr 1.6fr;
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 50px;
  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;

const ProfileHeadText = styled.div`
  padding: 10px 20px;
  border-left: ${theme.col.bluegrey[200]} 6px solid;
`;

const Name = styled.h1`
  font-size: 1.7rem;
`;

const Position = styled.p`
  margin-top: 0;
  color: ${theme.col.grey[900]};
  font-size: 1.3rem;
  position: relative;
`;

const FeaturedGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 40px;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const FeaturedCard = styled((props) => <Link {...props} />)`
  padding: ${(props) => props.cPad || "20px"};
  border-radius: 20px;
  background-color: ${theme.col.bw.w};
  color: ${theme.col.grey[900]};
  border: ${theme.col.grey[300]} solid 1px;
  transition: all 0.3s;
  line-height: 1.2;
  text-decoration: none;
  position: relative;
  display: grid;
  grid-template-columns: auto 24px;
  gap: 12px;
  & :hover {
    border: ${theme.col.primary.light} solid 1px;
    opacity: 1;
  }
`;

const FeaturedTitle = styled.span`
  display: block;
  font-size: 12px;
  line-height: 1.4em;
  font-weight: bold;
  margin: 0;
`;

const FeaturedRM = styled.span`
  display: block;
  font-size: 12px;
  line-height: 1.2em;
  margin: 0;
  margin-top: 5px;
  font-weight: 500;
  color: ${theme.col.primary.light};
`;

const Aligner = styled.div`
  display: flex;
  align-items: center;
`;
